<template>
<v-row>
    <v-col cols="12" md="12">
        <s-crud formPermanent no-full title="Mantenimiento Asesor" height="auto" :config="this.config" @save="save($event)" add edit remove @rowSelected="rowSelected($event)" searchInput>
            <template slot-scope="props">
                <v-container v-if="props.item != null">
                    <v-row justify="center">

                        <v-col cols="6" lg="3" md="3" class="s-col-form">
                            <s-text v-model="props.item.AdvDocument" label="Nro. Documento" />
                        </v-col>

                        <v-col cols="6" lg="4" md="4" class="s-col-form">
                            <s-text v-model="props.item.AdvName" label="Nombre(s)" />
                        </v-col>
                    </v-row>
                    <v-row justify="center">

                        <v-col cols="6" lg="3" md="3" class="s-col-form">
                            <s-text v-model="props.item.AdvSurnames" label="Apellido(s)" />
                        </v-col>

                        <v-col cols="6" lg="4" md="4" class="s-col-form">
                            <s-text v-model="props.item.AdvEmail" label="Email" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </v-col>

</v-row>
</template>

<script>
import _sAdviser from "@/services/Visits/VstAdviser";

export default {

    data: () => ({
        config: {
            model: {
                AdvID: "ID",
                AdvDocument: "string",
                AdvName: "string",
                AdvSurnames: "string",
                AdvEmail: "string",
                SecStatus: ""

            },

            service: _sAdviser,
            headers: [{
                    text: "ID",
                    value: "AdvID",
                    sorteable: false,
                    width: 10
                },
                {
                    text: "Nro. Documento",
                    value: "AdvDocument",
                    sorteable: false,
                    width: 30
                },
                {
                    text: "Nombres",
                    value: "AdvName",
                    sorteable: false,
                    width: 200
                },
                {
                    text: "Apellidos",
                    value: "AdvSurnames",
                    sorteable: false,
                    width: 200
                },
                {
                    text: "Email",
                    value: "AdvEmail",
                    sorteable: false,
                    width: 30
                },
                {
                    text: "Estado",
                    value: "SecStatus",
                    sorteable: false,
                    width: 10
                },

            ],
        },
    }),
    created() {

    },
    methods: {
        rowSelected(items) {

        },

        save(item) {
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID()

            if (item.AdvDocument.length == "" || item.AdvDocument == null || item.AdvDocument.length == 0) {
                this.$fun.alert("Ingrese Nro. Documento", "warning")
                return
            }

            if (item.AdvName.length == "" || item.AdvName == null || item.AdvName.length == 0) {
                this.$fun.alert("Ingrese Nombre", "warning")
                return
            }

            if (item.AdvSurnames.length == "" || item.AdvSurnames == null || item.AdvSurnames.length == 0) {
                this.$fun.alert("Ingrese Apellido", "warning")
                return
            }

            if (item.AdvEmail.length == "" || item.AdvEmail == null || item.AdvEmail.length == 0) {
                this.$fun.alert("Ingrese Correo", "warning")
                return
            }

            console.log(item)

            item.save()
        },

    },

};
</script>
